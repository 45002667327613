
import React from "react"
import Helmet from "react-helmet"
import "../styles/main.scss"

export default function Layout({ children }) {
    return (
        <>
         <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-E52FSFTW0N"></script>
<script>
  {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-E52FSFTW0N');`}
</script>
 
        <link rel="stylesheet" href="https://use.typekit.net/ynp0cpx.css" /> 
        </Helmet>
            {children}

        </>
    )
}